'use client';

import { classNames } from "@/lib/ui/utils";
import { useEffect, useRef, useState } from "react";


export default function Testimonials() {

  const testimonials = [
    {
      body: 'This service is fantastic. You cannot find a quicker, more accurate legal research. I am using it to secure my future. I could not get any other company to sell me a subscription to do my research to get answers to legal questions.',
      author: {
        name: 'John G.',
        handle: 'pro se litigant',
        imageUrl: '/common/silhouette.webp',
      },
    },
    {
      body: 'We are an in-house legal department. My research is for internal matters as they come up, or for responding to any demands or legal matters we handle in-house. Cetient gives real answers to legal questions.',
      author: {
        name: 'Nancy P.',
        handle: 'corporate paralegal',
        imageUrl: '/common/silhouette.webp',
      },
    },
    {
      body: 'I do volunteer research for incarcerated individuals. And you have done a great justice for people like me in this legal AI research because I cannot afford the expensive cost of the other sites.',
      author: {
        name: 'Guy I.',
        handle: 'volunteer researcher',
        imageUrl: '/common/silhouette.webp',
      },
    },
    {
      body: 'For me, as a self-represented litigant fighting two major financial institutions, this tool has been a lifeline. The number of hours it has saved me thus far likely numbering in the thousands.',
      author: {
        name: 'Annika E.',
        handle: 'pro se litigant',
        imageUrl: '/common/silhouette.webp',
      },
    },
  ];

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  // State to track if left/right scroll is possible
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);

  useEffect(() => {
    const scrollEl = scrollContainerRef.current;
    if (!scrollEl) return;

    // Check scroll positions and update state
    const checkScroll = () => {
      const { scrollLeft, scrollWidth, clientWidth } = scrollEl;
      // Left check
      setCanScrollLeft(scrollLeft > 0);
      // Right check
      setCanScrollRight(scrollLeft + clientWidth <= (scrollWidth - 5));
    };

    // Attach the event listener
    scrollEl.addEventListener("scroll", checkScroll);

    // Check immediately on mount
    checkScroll();

    // Cleanup
    return () => {
      scrollEl.removeEventListener("scroll", checkScroll);
    };
  }, []);

  const scrollLeft = () => {
    if (!scrollContainerRef.current) return;
    scrollContainerRef.current.scrollLeft -= scrollContainerRef.current.clientWidth;
  };

  const scrollRight = () => {
    if (!scrollContainerRef.current) return;
    scrollContainerRef.current.scrollLeft += scrollContainerRef.current.clientWidth;
  };


  return (
    <div className="relative text-sm">
      <button
        type="button"
        onClick={scrollLeft}
        disabled={!canScrollLeft}
        className={classNames(
          "absolute left-1 top-1/2 z-10 -translate-y-1/2 rounded-full bg-gray-300 text-black px-3 py-1 shadow",
          !canScrollLeft ? "opacity-50" : ""
        )}
      >
        ‹
      </button>

      <div
        ref={scrollContainerRef}
        className="-my-5 py-5 px-1 overflow-x-hidden scroll-smooth"
      >
        <div className="flex w-max items-stretch gap-x-2 ">
          {testimonials.map((testimonial) => (
            <figure
              key={testimonial.author.name}
              className="flex-none w-80 rounded-2xl bg-input-background py-3 px-5 shadow-lg ring-1 ring-secondary-ring"
            >
              <blockquote className="">
                <p>{`“${testimonial.body}”`}</p>
              </blockquote>
              <figcaption className="mt-6 flex items-center gap-x-4">
                <div>
                  <div className="font-semibold">-- {testimonial.author.name}</div>
                  <div className="text-tertiary-text">
                    {testimonial.author.handle}
                  </div>
                </div>
              </figcaption>
            </figure>
          ))}
        </div>
      </div>

      <button
        type="button"
        onClick={scrollRight}
        disabled={!canScrollRight}
        className={classNames(
          "absolute right-1 top-1/2 z-10 -translate-y-1/2 rounded-full bg-gray-300 text-black px-3 py-1 shadow",
          !canScrollRight ? "opacity-50" : ""
        )}
      >
        ›
      </button>
    </div>
  );

}
