"use client";

import { useEffect } from "react";

export default function DisableDragDrop() {
  useEffect(() => {
    const disableDragAndDrop = (event: DragEvent): void => {
      event.preventDefault();
    };

    document.body.addEventListener("dragover", disableDragAndDrop);
    document.body.addEventListener("drop", disableDragAndDrop);

    return () => {
      document.body.removeEventListener("dragover", disableDragAndDrop);
      document.body.removeEventListener("drop", disableDragAndDrop);
    };
  }, []);

  return null;
}
