'use client';

import { analyticsBrowser, trackBrowserEvent } from '@/lib/events/track-browser-event';
import { TrackingEvent } from '@/lib/events/track-event';
import { useReportWebVitals } from 'next/web-vitals';
import { useEffect, useRef } from "react";


export function VisitTracker() {
  const hasTracked = useRef<boolean>(false);
  const sentMetrics = useRef(new Set());

  useReportWebVitals((metric) => {
    if (!sentMetrics.current.has(metric.name)) {
      sentMetrics.current.add(metric.name);
      trackBrowserEvent(TrackingEvent.PageLoadTiming, {
        name: metric.name,
        value: metric.value,
        id: metric.id,
        label: metric.label,
        rating: metric.rating,
      });
    }
  });

  useEffect(() => {
    async function trackVisit() {
      const segmentAnonymousId = (await analyticsBrowser.user()).anonymousId();

      await fetch("/api/visit", {
        method: "POST",
        headers: {
          "x-cetient-ajs-anonymous-id": segmentAnonymousId || "",
          "x-cetient-browser-mode": window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? "dark" : "light",
          "X-Url": window.location.href,
          "X-Referrer": document.referrer,
        },
      });
    }

    if (!hasTracked.current) {
      trackVisit();
      hasTracked.current = true;
    }
  }, []);

  return (<></>);
}
