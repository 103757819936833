
"use client";

import { useEffect } from "react";
import { usePathname, useSearchParams } from "next/navigation";
import { analyticsBrowser } from "@/lib/events/track-browser-event";

export default function SegmentAnalytics() {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    analyticsBrowser.page();
  }, [pathname, searchParams]);

  return null;
}
