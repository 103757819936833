
export type UsageHints = Array<{category: string, question: string}>;

export default function usageHints(r: number): UsageHints {
  const hints = [
    {
      category: "Case law search",
      questions: [
        "John Deere bulldozer malfunction cases",
        "Wrongful tenant eviction cases in Michigan",
        "Equal Credit Opportunity Act cases in California",
        "Cases where KY courts apply the reasonable person standard",
        "What are strong nevada caselaws and statutes that enforce misclassification of easements",
        "What is Georgia’s code for Emotional stress and mental health",
        "Check treatment of \"State v. Brown, 697 P.2d 583\"",
        "Find similar cases to \"Gonzales v. INDUS. COM'N OF STATE\" \"740 P.2d 999\"",
      ],
    },
    {
      category: "Legal concepts",
      questions: [
        "What is a writ of possession",
        "What is the adverse domination doctrine",
        "What is leave to amend",
        "What is a defective indictment",
        "Explain the concept of error of law as it pertains to a motion for revision"
      ],
    },
    {
      category: "Legal procedures",
      questions: [
        "How do I compel a creditor to provide me with financial paperwork",
        "What is the burden of proof for an indictment",
        "Can you file a motion for leave to amend combined with a motion for relief from judgment",
        "What must be included in a Memorandum of Points and Authorities",
      ],
    },
    {
      category: "Modelling of legal scenarios",
      questions: [
        "Legal consequences if a plaintiff in a replevin action fails to post the required bond in Iowa",
        "What if a party to a contract breaches a confidentiality clause but claims they did so under duress in California",
      ],
    },
    {
      category: "Pro se litigants",
      questions: [
        "Do pro se litigants have any procedural flexibility in Oregon",
      ],
    },
    {
      category: "Drafting",
      questions: [
        "Draft a motion for judgment on the pleadings in a federal court",
        "Draft a letter for the Plaintiff",
        "Draft a complaint",
      ],
    },
  ];

  return hints.map( ({category, questions}) => {
    return {
      category,
      question: questions[Math.floor(r * questions.length)],
    };
  } );
}
