'use client';

import ChatApp, { ChatFeatures } from '@/components/chat-app';
import { Conversation } from '@/lib/conversations/conversation';
import usageHints from '@/lib/conversations/usage-hints';
import { uiEventsBus } from '@/lib/ui/notifications';
import { useRouter } from "next/navigation";
import Testimonials from './testimonials';
import { useEffect } from 'react';


export default function HomeContent({ name, header, subheader, forWhom, conversation, r }: { name: string, header: string, subheader: string, forWhom: string, conversation: Conversation, r: number }) {

  const router = useRouter();

  const ChatFeatures: ChatFeatures = {
    updatePageUrl: false,
    autoFocusChatInput: true,
    hasExpertCard: false,
    showFooter: false,
    inputOnlyMode: true,
  };

  useEffect(() => {
    const handler = ({ conversationId }: { conversationId: string }) => {
      router.push(`/research/${conversationId}`);
    };

    uiEventsBus.on("conversationCreatedOrCloned", handler);

    return () => {
      uiEventsBus.off("conversationCreatedOrCloned", handler);
    };
  }, [router]);

  return (
    <>
      <div className="mx-auto space-y-6 pt-6 sm:space-y-10 sm:pt-10 flex flex-col max-w-2xl">

        <h1 className="text-pop-text text-center text-2xl sm:text-4xl md:text-4xl lg:text-6xl font-semibold tracking-tight">
          {header}
        </h1>

        <div className="grow w-full pt-4 flex flex-col">
          <ChatApp
            conversation={conversation}
            features={ChatFeatures}
            usageHints={usageHints(r)}
            subheader={subheader}
          />
        </div>

        <div>
          <Testimonials />
        </div>

        <dl className="space-y-5">
          <div key="value0">
            <dt className="text-lg font-semibold">Our mission</dt>
            <dd className="mt-2 text-secondary-text">
              Our mission is to give legal professionals and the general public the superpower to understand the law.
            </dd>
          </div>

          <div key="value2">
            <dt className="text-lg font-semibold">How does {name} work?</dt>
            <dd className="mt-2 text-secondary-text">
              {name} uses AI to search, analyze, and summarize US case law and draft documents. Think of ChatGPT that has direct access to legal databases like LexisNexis or Westlaw.
              <sup>
                <a href="#tools-disclaimer">1</a>
              </sup>
            </dd>
          </div>

          <div key="value1">
            <dt className="text-lg font-semibold">What is {name}?</dt>
            <dd className="mt-2 text-secondary-text">
              {name} has access to over 9 million legal decisions from 2000+ U.S. federal and state courts. With {name} AI, {forWhom} can easily research important cases, understand legal concepts and procedures, draft documents, and conduct in-depth analysis using our legal data.
            </dd>
          </div>

          <div key="value3">
            <dt className="text-lg font-semibold">Which legal system does it apply to and can be used in?</dt>
            <dd className="mt-2 text-secondary-text">
              Our AI component is not restricted to a specific legal system or jurisdiction. However, the legal database search is limited to the United States.
            </dd>
          </div>

          <div key="value4">
            <dt className="text-lg font-semibold">How much does it cost?</dt>
            <dd className="mt-2 text-secondary-text">
              {name} is free for personal and professional use, with a limit of 42 messages per month. Pro plan is $79/month or $66/month when billed annually.
            </dd>
          </div>

          <div key="value5">
            <dt className="text-lg font-semibold">How accurate is this?</dt>
            <dd className="mt-2 text-secondary-text">
              We use generative AI models. The output may contain hallucinations and inaccurate information. You are responsible for reviewing and verifying the accuracy, appropriateness, and completeness of the content. We will do our best to include links to the full text of court opinions to assist you with the verification.
            </dd>
          </div>

          <div key="value6">
            <dt className="text-lg font-semibold">Do you provide legal advice?</dt>
            <dd className="mt-2 text-secondary-text">
              We are not attorneys or a law firm. Our platform is not intended to provide legal advice. Users should not ask legal questions or seek legal advice through our services.
            </dd>
          </div>
        </dl>

        <div id="tools-disclaimer" className="pb-5">
          <small className="text-xs text-secondary-text"><sup>1</sup> {name} is neither affiliated with nor endorsed by OpenAI, LexisNexis, and Thomson Reuters. Legal database is provided by CourtListener, a Free Law Project. We use multiple AI models, including OpenAI GPT 4o and o1, Anthropic Claude 3.5 Sonnet, Google Gemini, Meta Llama 3.1, DeepSeek R1 (hosted by US company in the US), and others.</small>
        </div>

      </div>
    </>

  );
}
